.ant-modal-content {
  border-radius: 0 0 10px 10px;
  border-top: none;
}

.ant-input {
  height: 28px;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
